import styled from 'styled-components';
import { Link } from 'gatsby';

import ChevronRightIcon from './chevron-right.svg';
import colors from '../../settings/colors';

export const CtaA = styled('a')`
  font-size: 1.5rem;
  color: ${colors.red100};
  margin-bottom: 4rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: '';
    background: url(${ChevronRightIcon});
    background-size: cover;
    margin-left: 0.5rem;
    margin-top: 0.175em;
    width: 0.325em;
    height: 0.61em;
  }
`;

export const CtaLink = styled(Link)`
  font-size: 1.5rem;
  color: ${colors.red100};
  margin-bottom: 4rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: '';
    background: url(${ChevronRightIcon});
    background-size: cover;
    margin-left: 0.5rem;
    margin-top: 0.175em;
    width: 0.325em;
    height: 0.61em;
  }
`;
