import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
  labels: [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
  ],
  datasets: [
    {
      label: 'OETZI Henkel 340',
      data: [
        91.68,
        86.92,
        82.97,
        79.47,
        76.36,
        73.53,
        70.93,
        68.55,
        66.28,
        64.2,
        62.24,
        60.37,
        58.64,
        56.99,
        55.45,
        54.02,
        52.64,
        51.35,
        50.0,
      ],
      fill: false, // Don't fill area under the line
      borderColor: '#8e5ea2',
    },
    {
      label: 'Herkömmliche Tasse',
      data: [91.68, 59.19, 50.0],
      fill: false, // Don't fill area under the line
      borderColor: '#3cba9f',
    },
  ],
};

const dataCold = {
  labels: [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
    210,
    220,
    230,
    240,
    250,
    260,
    270,
    280,
    290,
    300,
    310,
    320,
    330,
    340,
    350,
    360,
    370,
    380,
    390,
  ],
  datasets: [
    {
      label: 'OETZI Henkel 340',
      data: [
        5.6,
        4.8,
        4.1,
        3.53,
        3.44,
        3.44,
        3.49,
        3.5,
        3.5,
        3.5,
        3.53,
        3.57,
        3.64,
        3.76,
        3.95,
        4.16,
        4.4,
        4.64,
        4.89,
        5.14,
        5.4,
        5.66,
        5.92,
        6.18,
        6.45,
        6.73,
        6.99,
        7.24,
        7.49,
        7.75,
        7.99,
        8.23,
        8.47,
        8.7,
        8.92,
        9.14,
        9.36,
        9.58,
        9.78,
        9.99,
      ],
      fill: false, // Don't fill area under the line
      borderColor: '#8e5ea2',
    },
    {
      label: 'Herkömmliche Tasse',
      data: [5.72, 5.47, 5.85, 6.45, 7.33, 8.33, 10.0],
      fill: false, // Don't fill area under the line
      borderColor: '#3cba9f',
      drawTicks: false,
    },
  ],
};
const options = {
  elements: { point: { radius: 0, hitRadius: 3 } },
  maintainAspectRatio: false, // Don't maintain w/h ratio
  scales: {
    yAxes: [
      {
        display: true,
        scaleLabel: {
          labelString: 'Temperatur in °C',
          display: true,
        },
      },
    ],
    xAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Zeit in Stunden',
        },
        ticks: {
          callback(value) {
            return value % 60 === 0 ? value / 60 : null;
          },
        },
      },
    ],
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
};

export const HotChart = () => {
  return <Line data={data} options={options} />;
};

export const ColdChart = () => {
  return <Line data={dataCold} options={options} />;
};
