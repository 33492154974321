import styled from 'styled-components';
import colors from '../../settings/colors';

import Container from '../Container';

export const HeroWrapper = styled.div`
  border-bottom: 1px dashed ${colors.grey20};
`;

export const HeroContainer = styled(Container)`
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  h1 {
    text-transform: uppercase;

    span {
      display: block;
      text-transform: none;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`;

export const HeroImage = styled.div`
  min-width: 300px;
  max-width: 450px;
  margin-bottom: 4rem;
`;
