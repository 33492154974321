import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Product from '../components/Product';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`thermotasse`, `kafffee`, `oetzi`]} />
    <Hero />
    <Product />
  </Layout>
);

export default IndexPage;
