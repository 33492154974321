import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { CtaA } from '../CtaLink';

import { HeroWrapper, HeroContainer, HeroImage } from './styles';

const Henkel340Float = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "henkel340Redfloat.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
);

const Hero = () => (
  <HeroWrapper>
    <HeroContainer>
      <h1>
        <span>Henkel 340</span>Design Thermobecher mit Henkel
      </h1>
      <CtaA
        href="https://www.amazon.de/dp/B07XZGC645"
        onClick={() => {
          trackCustomEvent({
            category: 'Button',
            action: 'click',
            label: 'Amazon',
            value: 1,
          });
        }}
      >
        Jetzt auf Amazon kaufen
      </CtaA>
      <HeroImage>
        <Henkel340Float />
      </HeroImage>
    </HeroContainer>
  </HeroWrapper>
);

export default Hero;
