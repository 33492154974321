import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import BackgroundImage from 'gatsby-background-image';
import Grid from '@material-ui/core/Grid';
import { Box, Button } from '@material-ui/core';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import ImageCarousel from '../ImageCarousel';
import Container from '../Container';
import { HotChart, ColdChart } from '../Chart';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  ProductDescription,
  BuyBox,
  ColorPicker,
  Price,
  SectionHeadline,
  Section,
  SpecsSection,
  SpecImage,
  StyledGrid,
  EnvFriendSection,
  EnvFriendlyIcon,
} from './styles';

const query = graphql`
  query {
    henkel340sketch: file(relativePath: { eq: "henkel340_sketch.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    henkel340technical: file(
      relativePath: { eq: "henkel340technical_labeled.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350Scene: file(relativePath: { eq: "handle-350-scene.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    envFriendly: file(relativePath: { eq: "recycle_green.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: 'red',
      previousColor: 'red',
    };
  }

  changeColor = color => {
    this.setState(prevState => ({ color, previousColor: prevState.color }));
  };

  render() {
    const { color, previousColor } = this.state;
    return (
      <StaticQuery
        query={query}
        render={data => (
          <article>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <ImageCarousel color={color} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProductDescription>
                    <span className="title-span">
                      Thermobecher mit Henkel (0,34l)
                    </span>
                    <h2>Henkel 340</h2>

                    <p>
                      Die OETZI® HENKEL 340 ist ein durchdachter Becher für
                      Outdoor-Enthusiasten und Büro-Krieger. Die doppelwandig
                      vakuumierte Edelstahl Bauform sorgt dafür, dass Kaffee
                      oder Tee deutlich länger heiß bleiben. Obstsäfte oder
                      Wasser bleiben deutlich länger kalt. Und mit dem zeitlos
                      geometrisch-rundlichen Design sieht sie dabei schlicht und
                      modern aus.
                    </p>
                    <ColorPicker
                      title="Kamin Rot"
                      selected={color === 'red'}
                      color="red"
                      onClick={() => this.changeColor('red')}
                      onMouseEnter={() => this.changeColor('red')}
                      onMouseLeave={() => this.changeColor(previousColor)}
                    />
                    <ColorPicker
                      title="Marine Blau"
                      selected={color === 'blue'}
                      color="blue"
                      onClick={() => this.changeColor('blue')}
                      onMouseEnter={() => this.changeColor('blue')}
                      onMouseLeave={() => this.changeColor(previousColor)}
                    />
                    <BuyBox>
                      <Price>22,49€</Price>
                      <Button
                        variant="contained"
                        href="https://www.amazon.de/dp/B07XZDWKQ8"
                        size="large"
                        color="secondary"
                        onClick={() => {
                          trackCustomEvent({
                            category: 'Button',
                            action: 'click',
                            label: 'Amazon',
                            value: 1,
                          });
                        }}
                      >
                        Jetzt auf Amazon kaufen
                      </Button>
                    </BuyBox>
                  </ProductDescription>
                </Grid>
              </Grid>
            </Container>

            <SpecsSection>
              <Container>
                <SectionHeadline>Specs und Tech</SectionHeadline>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <ul>
                      <li>340 ml Fassungvermögen</li>
                      <li>304 Stainless Steel</li>
                      <li>Tritan Deckel mit lebensmittelechter Dichtung</li>
                      <li>Doppelwandige Vakuumisolierung</li>
                      <li>Extragrosser Griff für S,M,L und XL Hände</li>
                      <li>Kratzfeste Lackierung für extraharten Einsatz</li>
                      <li>Sehr leicht zu reinigen</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SpecImage>
                      <Img fluid={data.henkel340sketch.childImageSharp.fluid} />
                    </SpecImage>
                  </Grid>
                </Grid>
              </Container>
            </SpecsSection>

            <Section>
              <Container>
                <SectionHeadline>
                  Doppelwandige Vakuum Isolierung
                </SectionHeadline>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} />
                  <Grid item xs={12} md={6}>
                    <h3>3 Stunden lang über 50°C heiße Getränke</h3>
                  </Grid>
                </Grid>
                <StyledGrid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box
                      maxHeight="500px"
                      minHeight="300px"
                      marginTop="-2rem"
                      marginBottom="4rem"
                    >
                      <HotChart />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      Wir bei OETZI haben keine Mühen gescheut um euch genau zu
                      zeigen wie viel länger euer Getränk in unserer Tasse heiß
                      oder kalt bleibt. Für dieses Setup haben wir den direkten
                      vergleich zwischen einer handelsüblichen Tasse und unsere
                      OETZI Tasse gemacht. Die Tassen wurden mit je 340 ml 100
                      °C heißem Wasser gefüllt und den sich ergebenden
                      Temperaturverlauf dargestellt. Alle Daten wurden bei einer
                      konstanten Umgebungstemperatur von 20 °C aufgenommen.
                    </p>
                  </Grid>
                </StyledGrid>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <h3>6 Stunden lang unter 10°C kalte Getränke</h3>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <p>
                      Ob Eiskaffee, Cola oder Saft: Was kalt ist soll kalt
                      bleiben! Mit der OETZI Henkel 340 bleibt dein Kaltgetränk
                      über 6 Stunden lang gut gekühlt. Für die Messung der
                      Kaltgetränke haben wir die Tassen mit je 340 ml Wasser bei
                      8 °C und zwei Eiswürfeln befüllt.
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box minHeight="300px" maxHeight="500px" marginTop="-2rem">
                      <ColdChart />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Section>

            <SpecsSection>
              <Container>
                <SectionHeadline>Wie funktioniert das?</SectionHeadline>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <p>
                      Die OETZI Thermotasse ist extra dafür konzipiert die
                      Flüssigkeit im inneren von der Umwelt zu isolieren. In
                      normalen Tassen gibt die Flüssigkeit ihre Wärme an die
                      Tasse ab, welche wiederum die umgebende Luft aufheizt. Die
                      OETZI Tasse besteht aus zwei Wänden in derem Zwischenraum
                      ein Vakuum (Luftleerer Raum) herrscht. Die innere Wand der
                      Tasse wird durch den Kontakt mit der Flüssigekit
                      aufgeheizt, die Flüssigkeit verliert somit an Wärme. Durch
                      das Vakuum kann die innere Wand jedoch ihre Wärme nicht an
                      die Luft abgegen. Durch dieses Prinzip kann die OETZI
                      Tasse dein Getränk bis 3 Stunden lang ohne Probleme warm
                      halten. Ein ähnliches Prinzip gilt auch für kalte
                      Getränke, hierbei hält die Tasse sogar bis zu 6 Stunden
                      lang kalt.
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SpecImage>
                      <Img
                        fluid={data.henkel340technical.childImageSharp.fluid}
                      />
                    </SpecImage>
                  </Grid>
                </Grid>
              </Container>
            </SpecsSection>

            <BackgroundImage
              Tag="section"
              fluid={data.envFriendly.childImageSharp.fluid}
            >
              <Container>
                <EnvFriendSection>
                  <EnvFriendlyIcon />
                  <h2 color="0xffffff">Umweltbewusst</h2>
                  <p>
                    Jedes Jahr wandern Milliarden Einwegbecher von Bäckereien
                    und Cafés auf den Müll. Zeit, ein Zeichen zu setzen. Die EU
                    hat beschlossen, dass Polystyrol-Becher verboten werden
                    sollen, andere Einwegbecher gibt es nur noch gegen Aufpreis.
                    Die OETZI Tasse kannst du zigtausendfach wiederverwenden,
                    das ist besser für die Umwelt sowie für deinen Geldbeutel.
                  </p>
                  <Button
                    variant="contained"
                    href="https://www.amazon.de/dp/B07XZDWKQ8"
                    size="large"
                    color="secondary"
                    onClick={() => {
                      trackCustomEvent({
                        category: 'Button',
                        action: 'click',
                        label: 'Amazon',
                        value: 1,
                      });
                    }}
                  >
                    Jetzt auf Amazon kaufen
                  </Button>
                </EnvFriendSection>
              </Container>
            </BackgroundImage>
          </article>
        )}
      />
    );
  }
}

export default Product;
