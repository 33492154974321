import styled from 'styled-components';
import { rgba } from 'polished';
import Grid from '@material-ui/core/Grid';
import colors from '../../settings/colors';
import EnvIcon from '../../images/environment-friendly.inline.svg';

export const ProductDescription = styled.div`
  padding: 4rem 0;

  .title-span {
    color: ${colors.red100};
  }
`;

export const BuyBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ColorPicker = styled.button`
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  outline: none;
  border-width: 5px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? colors.grey20 : 'white')};
  margin: 0 0.5rem 1rem 0;
  cursor: pointer;
  background-color: ${({ color }) => {
    switch (color) {
      case 'blue':
        return '#25406d';
      case 'red':
        return '#ab364b';
      default:
        return '#ab364b';
    }
  }};
`;

export const Price = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 1rem;
`;

export const SectionHeadline = styled.h2`
  text-align: center;
  margin-bottom: 4rem;
`;

export const Section = styled.div`
  padding: 4rem 0;
`;

export const SpecsSection = styled(Section)`
  background: ${colors.grey10};
`;

export const SpecImage = styled.div`
  padding: 0rem 3rem;
`;

export const StyledGrid = styled(Grid)`
  @media (max-width: 959px) {
    flex-direction: column-reverse;
  }
`;

export const EnvFriendSection = styled.div`
  padding: 6rem 0;
  display: flex;
  flex-direction column;
  justify-content: center;
  align-items: center;
  color: white;

  h2 {
    text-transform: uppercase;
  }

  p {
    background: ${rgba('white', 0.7)};
    color: ${colors.grey120};
    max-width: 700px;
    border-radius: 4px;
    padding: 2rem;
  }
`;

export const EnvFriendlyIcon = styled(EnvIcon)`
  height: 5rem;
  width: 5rem;
  border: 2px solid white;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
`;
