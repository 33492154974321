import React from 'react';
import Slider from 'react-slick';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import ProductImage from './styles';

const settings = {
  dots: true,
  infinite: true,
  lazyLoad: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const query = graphql`
  query {
    handle350RedFront: file(relativePath: { eq: "henkel340_red_front.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350RedSide: file(relativePath: { eq: "henkel340_red_side.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350RedTop: file(relativePath: { eq: "henkel340_red_top.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350RedLid: file(relativePath: { eq: "henkel340_red_lid.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350BlueFront: file(relativePath: { eq: "henkel340_blue_front.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350BlueSide: file(relativePath: { eq: "henkel340_blue_side.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350BlueTop: file(relativePath: { eq: "henkel340_blue_top.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handle350BlueLid: file(relativePath: { eq: "henkel340_blue_lid.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const ImageCarousel = ({ color }) => {
  const data = useStaticQuery(query);
  if (color === 'blue') {
    return (
      <Slider {...settings}>
        <ProductImage>
          <Img fluid={data.handle350BlueFront.childImageSharp.fluid} />
        </ProductImage>
        <ProductImage>
          <Img fluid={data.handle350BlueSide.childImageSharp.fluid} />
        </ProductImage>
        <ProductImage>
          <Img fluid={data.handle350BlueTop.childImageSharp.fluid} />
        </ProductImage>
        <ProductImage>
          <Img fluid={data.handle350BlueLid.childImageSharp.fluid} />
        </ProductImage>
      </Slider>
    );
  }

  return (
    <Slider {...settings}>
      <ProductImage>
        <Img fluid={data.handle350RedFront.childImageSharp.fluid} />
      </ProductImage>
      <ProductImage>
        <Img fluid={data.handle350RedSide.childImageSharp.fluid} />
      </ProductImage>
      <ProductImage>
        <Img fluid={data.handle350RedTop.childImageSharp.fluid} />
      </ProductImage>
      <ProductImage>
        <Img fluid={data.handle350RedLid.childImageSharp.fluid} />
      </ProductImage>
    </Slider>
  );
};

ImageCarousel.defaultProps = {
  color: 'red',
};

ImageCarousel.propTypes = {
  color: PropTypes.string,
};

export default ImageCarousel;
